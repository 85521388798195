import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";

@NgModule({
	declarations: [FuseConfirmDialogComponent],
	imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
})
export class FuseConfirmDialogModule {}
