import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject, merge } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { NavigationEnd, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseConfig, fuseConfigAdministracion } from "app/fuse-config";
import { AuthService } from "app/services/auth/auth.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { VacacionesService } from "app/services/vacaciones/vacaciones.service";
import { User } from "app/shared/models/user.interface";

@Component({
	selector: "fuse-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
	public entorno: string = "WEB";
	private proyecto: any = "";

	@Input()
	layout = "vertical";

	@Input()
	navigation: any;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 *
	 * @param {ChangeDetectorRef} _changeDetectorRef
	 * @param {FuseNavigationService} _fuseNavigationService
	 */
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _fuseNavigationService: FuseNavigationService,
		private _projectService: ProjectService,
		private _userService: UserService,
		private vacationSvc: VacacionesService,
		private evaluationSvc: EvaluationService,
		private router: Router,
		private authSvc: AuthService,
		private _fuseConfigService: FuseConfigService
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();

		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				authSvc.setEntorno();
			}
		});

		this.authSvc.entorno.pipe(takeUntil(this._unsubscribeAll)).subscribe((entorno: string) => {
			if (this.entorno != entorno) {
				this.entorno = entorno;

				if (!this.router.url.includes("/login")) {
					switch (entorno) {
						case "ADMIN":
							this._projectService.setConfigProyectoAdmin();
							this._fuseConfigService.setConfig(fuseConfigAdministracion);
							break;
						default:
							this._projectService.setProyectoAdmin("");
							this._fuseConfigService.setConfig(fuseConfig);
					}
				}

				this.filterNavbarOptions();
			}
		});

		this.proyecto = this._projectService.getProyectoAdmin();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.authSvc.setEntorno();
		this.entorno = this.authSvc.entorno.getValue();

		// Load the navigation either from the input or from the service
		this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

		// Subscribe to the current navigation changes
		this._fuseNavigationService.onNavigationChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			// Load the navigation
			this.navigation = this._fuseNavigationService.getCurrentNavigation();
			// Mark for check
			this._changeDetectorRef.markForCheck();
		});

		// Subscribe to navigation item
		merge(
			this._fuseNavigationService.onNavigationItemAdded,
			this._fuseNavigationService.onNavigationItemUpdated,
			this._fuseNavigationService.onNavigationItemRemoved
		)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this._changeDetectorRef.markForCheck();
			});

		this.filterNavbarOptions();
	}

	filterNavbarOptions() {
		this._projectService.getProjectConfig(true, this.entorno).subscribe((data) => {
			//Se ha metido el menú de navegación en la configuración del proyecto (en los WS)
			let _navigation = data.navigation;
			this._userService.getUser().subscribe((user: User) => {
				//Habilitar menú de gastos si el usuario es jefe directo
				_navigation.find((value) =>
					value.children ? value.children.find((value) => (value.id == "expenses_team" ? (value.hidden = !user.boss) : null)) : null
				);

				this.vacationSvc.getListaTrabajadores(user.id).subscribe((data) => {
					if (data.length == 0) {
						_navigation.find((value) =>
							value.children ? value.children.find((value) => (value.id == "vacaciones_equipo" ? (value.hidden = true) : null)) : null
						);
					}
				});

				if (data["rolesWeb"] != 1) {
					//Si es syrsa, detectamos si el usuario tiene rol admin app para mostrarle las gráficas
					if (data.name == "syrsa") {
						let have_rol_admin = false;
						for (let elem of user["roles"]) {
							if (elem.rol == "ROLE_ADMIN_APP") {
								have_rol_admin = true;
							}
							if (elem.rol == "ADMIN_MARCA") {
								have_rol_admin = true;
							}
						}
						_navigation.find((value) => (value.id == "graficas" ? (value.hidden = !have_rol_admin) : null));
					}

					if (data.name != "cyg") {
						//Mostramos formaciones a todos menos BIENESTAR
						let have_rol_admin = false;
						for (let elem of user["roles"]) {
							if (elem.rol == "BIENESTAR" || elem.rol == "ROLE_ADMIN_APP") {
								have_rol_admin = true;
							}
						}
						_navigation.find((value) => (value.id == "formaciones-iberext" ? (value.hidden = !have_rol_admin) : null));
					}

					if (data.name == "cyg") {
						//Mostramos formaciones a todos menos BIENESTAR
						let have_rol_admin = false;
						for (let elem of user["roles"]) {
							if (elem.rol == "BIENESTAR") {
								have_rol_admin = true;
							}
						}
						_navigation.find((value) => (value.id == "formaciones-lite" ? (value.hidden = !have_rol_admin) : null));
					}
				}

				// Register the navigation to the service
				this._fuseNavigationService.unregister("main");
				this._fuseNavigationService.register("main", _navigation);
				// Set the main navigation as our current navigation
				this._fuseNavigationService.setCurrentNavigation("main");
			});
		});
	}

	refrescar() {
		this._changeDetectorRef.detectChanges();
	}
}
