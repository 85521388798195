import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject, merge } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { MatDialog } from "@angular/material/dialog";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseNavigationItem } from "@fuse/types";

import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { DialogDarBadgeComponent } from "../../../../../app/components/dialog-dar-badge/dialog-dar-badge.component";
@Component({
	selector: "fuse-nav-vertical-item",
	templateUrl: "./item.component.html",
	styleUrls: ["./item.component.scss"],
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
	@HostBinding("class")
	classes = "nav-item";

	@Input()
	item: FuseNavigationItem;
	user: any;
	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 */

	/**
	 *
	 * @param {ChangeDetectorRef} _changeDetectorRef
	 * @param {FuseNavigationService} _fuseNavigationService
	 */
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _fuseNavigationService: FuseNavigationService,
		public dialog: MatDialog,
		private evaluationSvc: EvaluationService,
		private _userService: UserService,
		private projectSvc: ProjectService,
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.refrescar();
		// Subscribe to navigation item
		merge(
			this._fuseNavigationService.onNavigationItemAdded,
			this._fuseNavigationService.onNavigationItemUpdated,
			this._fuseNavigationService.onNavigationItemRemoved,
		)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this._changeDetectorRef.markForCheck();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	runFunction(nameFunc: string) {
		if (this[nameFunc]) {
			this[nameFunc]();
		}
	}

	darBadgeOpenDialog() {
		const dialogRef = this.dialog.open(DialogDarBadgeComponent, {
			panelClass: ["panel-feedbalia"],
			width: window.screen.width > 768 ? "50vw" : "100vw",
			disableClose: true,
			autoFocus: false,
		});
		dialogRef.updatePosition({ top: "2rem" });
	}
	refrescar() {
		if (this.projectSvc.getProjectName() == "leroymerlin") {
			if (this.item) {
				if (this.item.id == "sied") {
					this._userService.getUser().subscribe((data) => {
						const person = data;
						this.evaluationSvc.getPendings(person.id).subscribe((data) => {
							if (data.title != "0") {
								this.item.badge = data;
								this._changeDetectorRef.detectChanges();
							}
						});
					});
				}
				if (this.item.id == "poll") {
					this._userService.getUser().subscribe((data) => {
						const person = data;
						// Active polls
						this.evaluationSvc.getPendingsPoll(person.id).subscribe((data) => {
							this.item.badge = data;
							console.log("Datos poll", person, data);
							this._changeDetectorRef.detectChanges();
						});
					});
				}
			}
		}
	}
}
