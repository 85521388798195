import { FuseConfig } from "@fuse/types";

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
	// Color themes can be defined in src/app/app.theme.scss
	colorTheme: "theme-default",
	customScrollbars: true,
	layout: {
		style: "vertical-layout-1",
		width: "fullwidth",
		navbar: {
			primaryBackground: "feedbalia-sidebar-500", //'fuse-navy-500',
			secondaryBackground: "feedbalia-navbar-logo-500", //'teal-400',
			folded: true,
			hidden: false,
			position: "left",
			variant: "vertical-style-2",
		},
		toolbar: {
			customBackgroundColor: true,
			background: "feedbalia-navbar-500", //'teal-400',
			hidden: false,
			position: "below-fixed",
		},
		footer: {
			customBackgroundColor: true,
			background: "feedbalia-sidebar-50", //'fuse-navy-50',
			hidden: false,
			position: "below-static",
		},
		sidepanel: {
			hidden: true,
			position: "right",
		},
	},
};

export const fuseConfigAdministracion: FuseConfig = {
	// Color themes can be defined in src/app/app.theme.scss
	colorTheme: "administracion-default",
	customScrollbars: true,
	layout: {
		style: "vertical-layout-1",
		width: "fullwidth",
		navbar: {
			primaryBackground: "feedbalia-sidebar-500",
			secondaryBackground: "feedbalia-navbar-500", //'teal-400',
			folded: false,
			hidden: false,
			position: "left",
			variant: "vertical-style-2",
		},
		toolbar: {
			customBackgroundColor: true,
			background: "feedbalia-navbar-500", //'teal-400',
			hidden: false,
			position: "below-fixed",
		},
		footer: {
			customBackgroundColor: true,
			background: "feedbalia-sidebar-50", //'fuse-navy-50',
			hidden: true,
			position: "below-static",
		},
		sidepanel: {
			hidden: true,
			position: "right",
		},
	},
};
